import React, { useContext } from 'react';
import Footer from '../../components/global/Footer';
import NavBar from '../../components/global/NavBar';
import Wrapper from '../../components/shared/Wrapper';
import Body from '../../components/template/Body';
import Intro from '../../components/template/Intro';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';

export default function template() {
  const { emitter, events } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const handleLogin = () => emitter.emit(events.AUTH_MODAL);

  return (
    <>
      <Wrapper>
        <NavBar />
        <Intro user={user} handleLogin={handleLogin} />
        <Body />
        <Footer />
      </Wrapper>
    </>
  );
}
