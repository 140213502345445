import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="bg-[#0F141F] w-full">
      <div className="container m-auto flex flex-col justify-center space-y-16 py-16">
        <div className="flex flex-col justify-center items-center md:flex-row space-x-0 space-y-9 md:space-x-9 md:space-y-0 ">
          <Link
            to="/pricing/step1"
            className="text-[#D5DBE8] font-normal text-[16px] hover:no-underline"
          >
            {t('components.footer.pricing')}
          </Link>
          <Link
            to="/terms-of-service"
            className="text-[#D5DBE8] font-normal text-[16px] hover:no-underline"
          >
            {t('components.footer.termsOfService')}
          </Link>
          <Link
            to="/"
            className="text-[#D5DBE8] font-normal text-[16px] hover:no-underline"
          >
            {t('components.footer.legalDocuments')}
          </Link>
          <Link
            to="/privacy-policy"
            className="text-[#D5DBE8] font-normal text-[16px] hover:no-underline"
          >
            {t('components.footer.privacyPolicy')}
          </Link>
          <Link
            to="/"
            className="text-[#D5DBE8] font-normal text-[16px] hover:no-underline"
          >
            {t('components.footer.rightOfWithdrawal')}
          </Link>
        </div>
        <div className="text-[#61646B] text-center text-[16px] font-medium">
          {t('components.footer.copyright')}
        </div>
      </div>
    </div>
  );
}
