import React, { memo, useContext } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { handleKeyUp } from '../../utils';
import { useDispatch } from '../../contexts/ResumeContext';
import ModalContext from '../../contexts/ModalContext';
import Template1 from '../../../static/images/templates/castform.png';
import Template2 from '../../../static/images/templates/celebi.png';
import Template3 from '../../../static/images/templates/gengar.png';
import Template4 from '../../../static/images/templates/glalie.png';
import Template5 from '../../../static/images/templates/onyx.png';
import Template6 from '../../../static/images/templates/pikachu.png';
import Template7 from '../../../static/images/templates/tokyo.png';
import Template8 from '../../../static/images/templates/amsterdam.png';
import Template9 from '../../../static/images/templates/berlin.png';

import Create from '../../../static/images/create-resume.png';
import colorOptions from '../../data/colorOptions';
import * as styles from '../builder/right/sections/Colors.module.css';
import Tick from '../../../static/images/green-tick.svg';
import UpgradeIcon from '../../../static/images/upgrade-icon.svg';

const template = [
  {
    id: 'castform',
    name: 'Castform',
    image: Template1,
  },
  {
    id: 'celebi',
    name: 'Celebi',
    image: Template2,
  },
  {
    id: 'gengar',
    name: 'Gengar',
    image: Template3,
  },
  {
    id: 'glalie',
    name: 'Glalie',
    image: Template4,
  },
  {
    id: 'onyx',
    name: 'Onyx',
    image: Template5,
  },
  {
    id: 'pikachu',
    name: 'Pikachu',
    image: Template6,
  },
  {
    id: 'tokyo',
    name: 'Tokyo',
    image: Template7,
  },
  {
    id: 'Amsterdam',
    name: 'Amsterdam',
    image: Template8,
  },
  {
    id: 'Berlin',
    name: 'Berlin',
    image: Template9,
  },
];

const createResumeButtonDataTestId = 'create-resume-button';

const CreateResume = ({ create, resumes }) => {
  const { emitter, events } = useContext(ModalContext);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState([]);
  const [premium, setPremium] = React.useState(false);
  const [limit, setLimit] = React.useState(false);

  const closeLimit = () => {
    setLimit(false);
  };

  // Removes color and template data on render
  React.useEffect(() => {
    localStorage.removeItem('color');
    localStorage.removeItem('template');
    const localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      const { isPremiumUser } = localUser;
      setPremium(isPremiumUser);
    }
  }, []);

  // Handles template click, sets selected template
  const handleClick = (id, type) => {
    if (premium === false && resumes.length > 0) {
      setLimit(true);
      return null;
    }

    if (id)
      dispatch({
        type: 'on_input_template_page',
        payload: {
          path: 'metadata.colors.primary',
          value: { templateId: id },
          type,
        },
      });
    else {
      localStorage.removeItem('color');
      localStorage.removeItem('template');
    }
    emitter.emit(events.CREATE_RESUME_MODAL);
  };
  // Handles color click and stores in localStorage
  const handleClickColor = (value, type, index) => {
    if (premium === false && resumes.length > 0) {
      setLimit(true);
      return null;
    }

    const temp = [...selected];
    temp.map((item, indexInner) => {
      if (indexInner !== index) {
        temp[indexInner] = false;
      }
      return true;
    });
    temp[index] = !temp[index];
    setSelected(temp);

    if (value)
      dispatch({
        type: 'on_input_template_page',
        payload: {
          path: 'metadata.colors.primary',
          value: { color: value, set: temp[index] },
          type,
        },
      });
  };

  if (create) {
    return (
      <>
        <div
          className={`${
            limit === true ? 'flex' : 'hidden'
          } fixed justify-center items-center h-full w-full bg-[#2835506b] top-0 left-0`}
        >
          <div className="bg-white flex absolute  w-[80%] h-[55%] md:w-[50%] md:h-[45%]  rounded-2xl shadow-xl">
            <button
              onClick={closeLimit}
              className="absolute text-[#CFD6E5] text-[34px] float-right right-4 font-mono font-thin"
            >
              x
            </button>
            <div className="hidden md:flex justify-center items-center w-2/6">
              <img src={UpgradeIcon} alt="upgrade icon" />
            </div>
            <div className="flex flex-col space-y-4 justify-center items-start px-5 w-full md:w-4/6">
              <h1 className="text-[#293F67] font-semibold text-[24px]">
                {t('modals.subscriptionCreate.title')}
              </h1>
              <p className="text-[#53587E] text-[16px]">
                {t('modals.subscriptionCreate.description')}
              </p>

              <p className="text-[#293F67] text-[16px]">
                {t('modals.subscriptionCreate.also')}
              </p>

              <div className="flex space-x-3">
                <img src={Tick} alt="green tick" />
                <p className="text-[#53587E] text-[16px]">
                  {t('modals.subscriptionCreate.features.0')}
                </p>
              </div>
              {/* <div className="flex space-x-3">
                <img src={Tick} alt="green tick" />
                <p className="text-[#53587E] text-[16px]">
                {t('modals.subscriptionCreate.features.1')}
                </p>
              </div> */}
              <div className="flex space-x-3">
                <Link
                  to="/pricing/step1"
                  className="bg-[#3346D3] p-4 text-white text-[16px] rounded-2xl shadow-md hover:no-underline"
                >
                  {t('shared.buttons.upgrade')}
                </Link>
                <button
                  onClick={closeLimit}
                  className=" p-4 text-[16px] rounded-2xl border-[1px] border-solid border-[#c9c9c9] shadow-md"
                >
                  {t('shared.buttons.skip')}
                </button>
              </div>
            </div>
          </div>
        </div>

        {template.map((data) => (
          <button
            data-testid={createResumeButtonDataTestId}
            className="w-full md:w-[30%] mt-8 bg-[#F2F5FA] px-8 py-6"
          >
            <div
              onClick={() => handleClick(data.id, 'template')}
              onKeyUp={(e) =>
                handleKeyUp(e, () => handleClick(data.id, 'template'))
              }
              role="button"
              tabIndex="0"
            >
              <img src={data.image} alt={data.name} />
            </div>
            <div className="flex mt-5 ">
              <h1 className="text-[16px] w-[55%] flex items-center font-semibold">
                {t('template.singleTemplate.title')}
              </h1>
              <div className="flex justify-end items-center w-[45%] space-x-2">
                <div className="bg-[#B8BECB] flex h-10 w-[230px] items-center justify-center rounded-lg text-white">
                  PDF
                </div>
                <div className="bg-[#B8BECB] flex h-10 w-[230px] items-center justify-center rounded-lg text-white">
                  DOCX
                </div>
                <div className="bg-[#B8BECB] flex h-10 w-[230px] items-center justify-center rounded-lg text-white">
                  PPTX
                </div>
              </div>
            </div>
            <div className="border-[#DDE3EF] border-[1px] border-solid mt-4" />
            <div className="flex mt-4 ">
              <h1 className="text-[14px] flex items-start w-[35%] font-medium">
                {t('template.singleTemplate.colors')}
              </h1>
              <div className="flex justify-start w-[65%] space-x-2">
                <div className="mb-6 grid grid-cols-12 gap-x-2 gap-y-6">
                  {colorOptions.map((color, index) => (
                    <div
                      key={color}
                      aria-label="Select color"
                      tabIndex="0"
                      role="button"
                      className={styles.circle}
                      style={{
                        backgroundColor: color,
                        opacity: selected[index] ? '0.5' : '1',
                      }}
                      onKeyUp={(e) =>
                        handleKeyUp(e, () =>
                          handleClickColor(color, 'color', index),
                        )
                      }
                      onClick={() => handleClickColor(color, 'color', index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </button>
        ))}
      </>
    );
  }

  return (
    <div className="flex w-full justify-around pb-6">
      {/* <div className={styles.backdrop}>
        <MdAdd size="48" />
      </div> */}
      <div
        data-testid={createResumeButtonDataTestId}
        tabIndex="0"
        role="button"
        className="h-[300px] w-[35%] border-[1px] border-solid border-[#DEE2E8] rounded-lg flex items-center justify-center"
        onClick={() => handleClick(null, 'create')}
        onKeyUp={(e) => handleKeyUp(e, handleClick(null, 'create'))}
      >
        <img src={Create} className="w-24" alt="create" />
      </div>
      <div className="flex flex-col items-start  justify-center w-[50%]">
        <p className="text-[#293F67]  font-medium text-[22px]">
          {t('dashboard.createResume')}
        </p>
        <p className="text-[#97A5BD] text-[17px] mt-3">
          {t('dashboard.createResumeDescriptions')}
        </p>
      </div>
    </div>
  );
};

export default memo(CreateResume);

export { createResumeButtonDataTestId };
