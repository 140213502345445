import { useLocation } from '@reach/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { navigate, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
// import GoogleOneTapLogin from 'react-google-one-tap-login';
import { pick } from 'lodash';
import firebase from 'gatsby-plugin-firebase';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import axios from 'axios';
import { toast } from 'react-toastify';
import logo from '../../../static/images/logo-main.svg';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import { onLinkedInRedirect } from '../../services/firebaseService';

export default function NavBar() {
  const location = useLocation();
  const [mobile, setMobile] = useState(false);
  const [loadingNewUser, setLoadingNewUser] = useState(false);
  const { emitter, events } = useContext(ModalContext);
  const { user, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const handleLogin = () => emitter.emit(events.AUTH_MODAL);

  const handleGotoApp = () => navigate('/app/dashboard');

  const ShowMenu = () => {
    if (mobile === true) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  const handleNewUsers = async (userNew) => {
    setLoadingNewUser(true);
    setTimeout(() => {
      const userNewData = pick(
        userNew,
        Object.keys({
          uid: null,
          email: null,
          displayName: null,
          isAnonymous: false,
        }),
      );
      setUser(userNewData);
      localStorage.setItem('user', JSON.stringify(userNewData));
      setLoadingNewUser(false);
    }, 3000);
  };

  if (typeof window !== 'undefined')
    useGoogleOneTapLogin({
      disabled: user,
      onError: (error) => console.log(error),
      onSuccess: (response) => {
        firebase
          .auth()
          .signInWithCredential(
            firebase.auth.GoogleAuthProvider.credential(response.credential),
          )
          .then((resp) => {
            if (resp)
              if (resp.additionalUserInfo.isNewUser) {
                handleNewUsers(resp.user);
              }
          })
          .catch(() => {
            setLoadingNewUser(false);
          });
      },
      googleAccountConfigs: {
        client_id:
          '871584796768-c5nea40n67t5uj2je9654itonvo7jdg9.apps.googleusercontent.com',
      },
    });

  const handleLinkedIn = useCallback(async () => {
    try {
      setLoadingNewUser(true);
      const userLogin = await onLinkedInRedirect(location.search);
      if (userLogin.accessToken) {
        const res = await axios.post(
          'https://asia-east2-resume-maker-cbcc7.cloudfunctions.net/linkedin/linkedin-firebase',
          userLogin,
        );
        if (res.data.token && res.data.status) {
          await firebase.auth().signInWithCustomToken(res.data.token);
          const userNewData = {
            uid: userLogin.linkedinID,
            email: userLogin.email,
            displayName: userLogin.name,
            isAnonymous: false,
          };
          setUser(userNewData);
          localStorage.setItem('user', JSON.stringify(userNewData));
          setLoadingNewUser(false);
        } else {
          setLoadingNewUser(false);
          toast.error(res.data.error || 'Something went wrong');
        }
        // console.log('token from linkedin-firebase===>', res);
      }
    } catch (e) {
      console.log(e);
      setLoadingNewUser(false);
    }
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (!user && code) {
      handleLinkedIn();
    }
  }, []);

  return (
    <div className="flex justify-around container py-6">
      {/* <GoogleOneTapLogin
        disabled={user}
        onError={(error) => console.log(error)}
        onSuccess={(response) => {
          firebase
            .auth()
            .signInWithCredential(
              firebase.auth.GoogleAuthProvider.credential(response.credential),
            )
            .then((resp) => {
              if (resp)
                if (resp.additionalUserInfo.isNewUser) {
                  handleNewUsers(resp.user);
                }
            })
            .catch(() => {
              setLoadingNewUser(false);
            });
        }}
        googleAccountConfigs={{
          client_id:
            '871584796768-c5nea40n67t5uj2je9654itonvo7jdg9.apps.googleusercontent.com',
        }}
      /> */}

      <div className="flex justify-left space-x-2 items-center w-2/4 md:w-3/12 ml-6">
        {' '}
        <Link
          to="/"
          className="font-semibold  text-[18px] text-[#363C4B] hover:no-underline"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="logo" className="mr-2" />
            SimpleCV{' '}
          </div>
        </Link>
      </div>
      <button
        onClick={ShowMenu}
        className="flex w-1/4 items-center align-middle justify-center mr-6 md:hidden rounded-2xl font-semibold bg-[#3346D3] text-white"
      >
        menu
      </button>

      <div
        className={`${
          mobile === true ? 'flex' : 'hidden'
        } absolute flex-col md:flex-row md:relative top-28 md:top-0 md:flex bg-white md:bg-transparent border-2 border-solid md:border-none w-full md:w-9/12 pb-8 md:pb-0`}
      >
        <div className="w-full md:w-[80%] pr-0 md:pr-6 pt-6 md:pt-0 flex-col md:flex-row flex justify-end items-center space-x-0 md:space-x-12 space-y-8 md:space-y-0">
          <Link
            to="/"
            className={`${
              location.pathname === '/' ? 'text-[#152B7C]' : 'text-[#9294A7]'
            } duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}
          >
            {t('components.navbar.home')}
          </Link>
          {/* <Link to='/resume' className={`${location.pathname === '/resume' ? 'text-[#152B7C]' : 'text-[#9294A7]'} duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}>Resume</Link> */}

          {user ? (
            user.isPremiumUser ? (
              ''
            ) : (
              <Link
                to="/pricing/step1"
                className={`${
                  location.pathname === '/pricing'
                    ? 'text-[#152B7C]'
                    : 'text-[#9294A7]'
                } duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}
              >
                {t('components.navbar.pricing')}
              </Link>
            )
          ) : (
            <Link
              to="/pricing/step1"
              className={`${
                location.pathname === '/pricing'
                  ? 'text-[#152B7C]'
                  : 'text-[#9294A7]'
              } duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}
            >
              {t('components.navbar.pricing')}
            </Link>
          )}
          <Link
            to="/template"
            className={`${
              location.pathname === '/template'
                ? 'text-[#152B7C]'
                : 'text-[#9294A7]'
            } duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}
          >
            {t('components.navbar.templates')}
          </Link>
          {/* <Link to='/contact' className={`${location.pathname === '/contact' ? 'text-[#152B7C]' : 'text-[#9294A7]'} duration-500 text-[14px] font-semibold hover:text-[#152B7C] hover:no-underline`}>Contact</Link> */}
        </div>
        <div className="w-full md:w-[20%] flex mt-8 md:mt-0 justify-center md:justify-start items-center space-x-8 md:space-x-4 mr-0 md:mr-2">
          {user ? (
            <button
              onClick={handleGotoApp}
              className="text-[14px]  font-semibold text-[#152B7C] border-solid border-l-[2px] pl-6 hover:no-underline"
            >
              {t('components.navbar.goToApp')}
            </button>
          ) : (
            <>
              <button
                disabled={loadingNewUser}
                onClick={handleLogin}
                className={`text-[14px]  font-semibold ${
                  loadingNewUser ? 'text-white' : 'text-[#152B7C]'
                } border-none md:border-solid border-l-[2px] pl-6 hover:no-underline`}
              >
                {t('components.navbar.login')}
              </button>
              <Link
                to="/signup"
                className="text-[14px] w-[112px] py-5 text-center rounded-2xl font-semibold bg-[#3346D3] text-white hover:no-underline"
              >
                {loadingNewUser ? (
                  <div className="flex justify-center align-middle">
                    <svg
                      className="animate-spin -inline-block w-7 h-7 border-4 rounded-full "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx={12}
                        cy={12}
                        r={10}
                        stroke="currentColor"
                        strokewidth={4}
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </div>
                ) : (
                  t('components.navbar.signup')
                )}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
