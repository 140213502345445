import React, { useEffect, useState, useContext } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { useTranslation } from 'react-i18next';
import Template1 from '../../../static/images/templates/castform.png';
import Template2 from '../../../static/images/templates/celebi.png';
import Template3 from '../../../static/images/templates/gengar.png';
import Template4 from '../../../static/images/templates/glalie.png';
import Template5 from '../../../static/images/templates/onyx.png';
import Template6 from '../../../static/images/templates/pikachu.png';
import Template7 from '../../../static/images/templates/tokyo.png';
import Template8 from '../../../static/images/templates/amsterdam.png';
import Template9 from '../../../static/images/templates/berlin.png';
import ModalContext from '../../contexts/ModalContext';
import CreateResume from '../dashboard/CreateResume';
import UserContext from '../../contexts/UserContext';

const template = [
  {
    id: 1,
    name: 'Castform',
    image: Template1,
  },
  {
    id: 2,
    name: 'Celebi',
    image: Template2,
  },
  {
    id: 3,
    name: 'Gengar',
    image: Template3,
  },
  {
    id: 4,
    name: 'Glalie',
    image: Template4,
  },
  {
    id: 5,
    name: 'Onyx',
    image: Template5,
  },
  {
    id: 6,
    name: 'Pikachu',
    image: Template6,
  },
  {
    id: 7,
    name: 'Tokyo',
    image: Template7,
  },
  {
    id: 8,
    name: 'Amsterdam',
    image: Template8,
  },
  {
    id: 9,
    name: 'Berlin',
    image: Template9,
  },
];

export default function Body() {
  const { emitter, events } = useContext(ModalContext);
  const { user, loading } = useContext(UserContext);
  const handleLogin = () => emitter.emit(events.AUTH_MODAL);
  const { t } = useTranslation();

  const [resumes, setResumes] = useState([]);

  useEffect(async () => {
    const resumesRef = 'resumes';
    const socketRef = '/.info/connected';
    const localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser && user) {
      const { uid } = localUser;
      const userRef = firebase.database().ref(`users/${uid}`);
      const userSnapshot = await userRef.once('value');
      userSnapshot.val() &&
        localStorage.setItem('user', JSON.stringify(userSnapshot.val()));

      firebase
        .database()
        .ref(socketRef)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            firebase.database().ref(socketRef).off();
          }
        });

      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('user')
        .equalTo(user.uid)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            const resumesArr = [];
            const data = snapshot.val();
            Object.keys(data).forEach((key) => resumesArr.push(data[key]));
            setResumes(resumesArr);
          }
        });

      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('user')
        .equalTo(user.uid)
        .on('child_removed', (snapshot) => {
          if (snapshot.val()) {
            setResumes(resumes.filter((x) => x.id === snapshot.val().id));
          }
        });

      return () => {
        firebase.database().ref(resumesRef).off();
      };
    }
  }, [user]);

  return (
    <div className="container px-10 pb-10">
      <div className="border-[#DDE3EF] border-[1px] border-solid " />
      <div className="flex justify-between flex-wrap mt-10 ">
        {user ? (
          <CreateResume create resumes={resumes} />
        ) : (
          template.map((data) => (
            <button
              onClick={handleLogin}
              isLoading={loading}
              className="w-full md:w-[30%] mt-8 bg-[#F2F5FA] px-8 py-6"
            >
              <img src={data.image} alt={data.name} />
              <div className="flex mt-5 ">
                <h1 className="text-[16px] w-[55%] flex items-center font-semibold">
                  {t('template.singleTemplate.title')}
                </h1>
                <div className="flex justify-end items-center w-[45%] space-x-2">
                  <div className="bg-[#B8BECB] flex h-10 w-[230px] items-center justify-center rounded-lg text-white">
                    PDF
                  </div>
                  <div className="bg-[#B8BECB] flex h-10 w-[230px] items-center justify-center rounded-lg text-white">
                    DOCX
                  </div>
                  <div className="bg-[#B8BECB] flex h-10 w-[230px] items-center justify-center rounded-lg text-white">
                    PPTX
                  </div>
                </div>
              </div>
              <div className="border-[#DDE3EF] border-[1px] border-solid mt-4" />
              <div className="flex mt-4 ">
                <h1 className="text-[14px] flex items-center w-[35%] font-medium">
                  {t('template.singleTemplate.colors')}
                </h1>
                <div className="flex justify-start w-[65%] space-x-2">
                  <div className="bg-[#000000]  h-[28px] w-[28px] items-center justify-center rounded-full text-white" />
                  <div className="bg-[#673FB4]  h-[28px] w-[28px]  items-center justify-center rounded-full text-white" />
                  <div className="bg-[#278AD9]  h-[28px] w-[28px]  items-center justify-center rounded-full text-white" />
                  <div className="bg-[#2CA763]  h-[28px] w-[28px]  items-center justify-center rounded-full text-white" />
                  <div className="bg-[#FC4F52]  h-[28px] w-[28px]  items-center justify-center rounded-full text-white" />
                  <div className="bg-[#FC8D4F]  h-[28px] w-[28px]  items-center justify-center rounded-full text-white" />
                </div>
              </div>
            </button>
          ))
        )}
      </div>
    </div>
  );
}
