import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Intro({ user, handleLogin }) {
  const { t } = useTranslation();
  return (
    <div className="w-full pb-20">
      <div className="container flex items-center justify-center m-auto pt-28 flex-col">
        <h2 className="font-bold text-center text-[#152B7C] mt-6 text-[34px]">
          {t('template.title.0')}
          <br />
          {t('template.title.1')}
        </h2>
        <p className="text-[#7C8296]  text-center mt-4 text-[24px]">
          {t('template.description.0')}
          <br />

          {t('template.description.1')}
        </p>
        {user ? (
          <Link
            to="/app/dashboard"
            className="bg-[#3346D3]  w-[214px] m-auto mt-8 md:m-0 md:mt-8 text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline"
          >
            {t('shared.buttons.create')}
          </Link>
        ) : (
          <button
            onClick={(e) => handleLogin(e)}
            className="bg-[#3346D3]  w-[214px] m-auto mt-8 md:m-0 md:mt-8 text-[16.5px] text-center py-6 rounded-2xl text-white hover:no-underline font-semibold"
          >
            {t('shared.buttons.create')}
          </button>
        )}{' '}
      </div>
    </div>
  );
}
